
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicTable from "@/views/resources/documentation/element-ui/data/table/BasicTable.vue";
import EUIStripedTable from "@/views/resources/documentation/element-ui/data/table/StripedTable.vue";
import EUITableWithBorder from "@/views/resources/documentation/element-ui/data/table/TableWithBorder.vue";
import EUITableWithStatus from "@/views/resources/documentation/element-ui/data/table/TableWithStatus.vue";
import EUITableWithFixedHeader from "@/views/resources/documentation/element-ui/data/table/TableWithFixedHeader.vue";
import EUITableWithFixedColumn from "@/views/resources/documentation/element-ui/data/table/TableWithFixedColumn.vue";
import EUITableWithFixedColumnsAndHeader from "@/views/resources/documentation/element-ui/data/table/TableWithFixedColumnsAndHeader.vue";
import EUIFluidHeightTableWithFixedHeaderAndColumns from "@/views/resources/documentation/element-ui/data/table/FluidHeightTableWithFixedHeaderAndColumns.vue";
import EUIGroupingTableHead from "@/views/resources/documentation/element-ui/data/table/GroupingTableHead.vue";
import EUISingleSelect from "@/views/resources/documentation/element-ui/data/table/SingleSelect.vue";
import EUIMultipleSelect from "@/views/resources/documentation/element-ui/data/table/MultipleSelect.vue";
import EUISorting from "@/views/resources/documentation/element-ui/data/table/Sorting.vue";
import EUIFilter from "@/views/resources/documentation/element-ui/data/table/Filter.vue";
import EUICustomColumnTemplate from "@/views/resources/documentation/element-ui/data/table/CustomColumnTemplate.vue";
import EUITableWithCustomHeader from "@/views/resources/documentation/element-ui/data/table/TableWithCustomHeader.vue";
import EUIExpandableRow from "@/views/resources/documentation/element-ui/data/table/ExpandableRow.vue";
import EUITreeDataAndLazyMode from "@/views/resources/documentation/element-ui/data/table/TreeDataAndLazyMode.vue";
import EUISummaryRow from "@/views/resources/documentation/element-ui/data/table/SummaryRow.vue";
import EUIRowspanAndColspan from "@/views/resources/documentation/element-ui/data/table/RowspanAndColspan.vue";
import EUICustomIndex from "@/views/resources/documentation/element-ui/data/table/CustomIndex.vue";

export default defineComponent({
  name: "table",
  components: {
    EUIBasicTable,
    EUIStripedTable,
    EUITableWithBorder,
    EUITableWithStatus,
    EUITableWithFixedHeader,
    EUITableWithFixedColumn,
    EUITableWithFixedColumnsAndHeader,
    EUIFluidHeightTableWithFixedHeaderAndColumns,
    EUIGroupingTableHead,
    EUISingleSelect,
    EUIMultipleSelect,
    EUISorting,
    EUIFilter,
    EUICustomColumnTemplate,
    EUITableWithCustomHeader,
    EUIExpandableRow,
    EUITreeDataAndLazyMode,
    EUISummaryRow,
    EUIRowspanAndColspan,
    EUICustomIndex
  },
  setup() {
    setCurrentPageTitle("Table");
  }
});
